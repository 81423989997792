import {
  IconDefinition,
  faArrowUp,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faUtensilsAlt,
  faFistRaised,
  faHandHoldingHeart,
  faMobileAlt,
  faTelescope,
  faTableTennis,
  faBars,
  faGears,
  faRocketLaunch,
  faUsers,
  faComment,
  faCommentDots,
  faClock,
  faCode,
  faCodeBranch,
  faMugSaucer,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  faFacebook,
  faTwitter,
  faLinkedinIn,
  faGithubSquare,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import {
  faAlarmClock,
  faBowlingPins,
  faGamepadModern,
  faHouse,
  faMoneyBillWave,
  faSalad,
  faSuitcase,
  faUmbrellaBeach,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './SRWIcon.module.scss';

const socials = [
  'twitter',
  'linkedIn',
  'gitHub',
  'envelope',
  'facebook',
] as const;
const faIcons = [
  'arrowUp',
  'arrowLeft',
  'arrowRight',
  'arrowDown',
  'utensils',
  'fistRaised',
  'tableTennis',
  'telescope',
  'times',
  'mobileAlt',
  'burgerMenu',
  'gears',
  'handHoldingHeart',
  'rocketLaunch',
  'users',
  'comment',
  'commentDots',
  'clock',
  'code',
  'codeBranch',
  'mugSaucer',
  'wrench',
] as const;
const duoIcons = [
  'alarmClock',
  'bowlingPins',
  'gamepadModern',
  'house',
  'moneyBillWave',
  'salad',
  'suitcase',
  'umbrellaBeach',
] as const;

export type SocialIconNames = typeof socials[number];
type IconNames = typeof faIcons[number];
type DuoIconNames = typeof duoIcons[number];

export type IconNameType = SocialIconNames | IconNames | DuoIconNames;

const icons: { [key in IconNameType]: IconDefinition } = {
  alarmClock: faAlarmClock,
  arrowDown: faArrowDown,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowUp: faArrowUp,
  bowlingPins: faBowlingPins,
  burgerMenu: faBars,
  clock: faClock,
  code: faCode,
  codeBranch: faCodeBranch,
  comment: faComment,
  commentDots: faCommentDots,
  envelope: faEnvelope,
  facebook: faFacebook,
  fistRaised: faFistRaised,
  gamepadModern: faGamepadModern,
  gears: faGears,
  gitHub: faGithubSquare,
  handHoldingHeart: faHandHoldingHeart,
  house: faHouse,
  linkedIn: faLinkedinIn,
  mobileAlt: faMobileAlt,
  moneyBillWave: faMoneyBillWave,
  mugSaucer: faMugSaucer,
  rocketLaunch: faRocketLaunch,
  salad: faSalad,
  suitcase: faSuitcase,
  tableTennis: faTableTennis,
  telescope: faTelescope,
  times: faTimes,
  twitter: faTwitter,
  umbrellaBeach: faUmbrellaBeach,
  users: faUsers,
  utensils: faUtensilsAlt,
  wrench: faWrench,
};

export const isValidIcon = (name: any) => {
  if (
    !name ||
    (!socials.includes(name) &&
      !faIcons.includes(name) &&
      !duoIcons.includes(name))
  ) {
    return false;
  }
  return true;
};

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  name: IconNameType;
  width?: string;
  onClick?: () => void;
}
const SRWIcon = (props: Props): JSX.Element => {
  const { name, onClick, width, className, ...otherProps } = props;
  const isDuotone = duoIcons.includes(name as any);
  return (
    <FontAwesomeIcon
      {...otherProps}
      icon={icons[name]}
      className={classnames(styles.icon, className, {
        [styles.clickable]: !!onClick,
        [styles.duotone]: isDuotone,
      })}
      onClick={onClick}
    />
  );
};

export default SRWIcon;
