import React, { FC } from "react";

export type SocialCard = {
  social_card_image: {
    dimensions: { width: number; height: number };
    alt: string | null;
    copyright: string | null;
    url: string;
  };
  social_card_title: string;
  social_card_description: string;
};
export type MetaData = {
  meta_title: string;
  meta_description: string;
  social_cards: SocialCard[];
  url: string;
};

type Props = {
  metaData: {
    title?: string;
    description?: string;
    social_cards?: SocialCard[];
  };
  noIndex?: boolean;
  url?: string;
};

const MetaTags: FC<Props> = ({ metaData, noIndex, url }) => {
  const image =
    metaData.social_cards &&
    metaData.social_cards.map((sc) => sc.social_card_image.url).pop();
  return (
    <>
      {metaData.title && (
        <>
          <meta name="twitter:title" content={metaData.title} />
          <meta property="og:title" content={metaData.title} />
        </>
      )}
      {metaData.description && (
        <>
          <meta name="description" content={metaData.description} />
          <meta name="twitter:description" content={metaData.description} />
          <meta property="og:description" content={metaData.description} />
        </>
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={`@siliconrhino`} />

      {image && (
        <>
          <meta name="twitter:image" content={image} />
          <meta property="og:image" content={image} />
        </>
      )}

      <meta property="og:type" content={"website"} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content={"Silicon Rhino"} />
      {noIndex === true && <meta name="robots" content="noindex,nofollow" />}
    </>
  );
};

export default MetaTags;
