import React, { FC, Fragment } from 'react';
import Head from 'next/head';
import Header from '../Header';
import Footer from '../Footer';
import MetaTags, { MetaData } from '../MetaTags';
import BackToTop from '../BackToTop';
import useScrollDirection from '../../hooks/useScrollDirection';

type Props = {
  data?: MetaData;
  menu: any;
  footer?: any;
  omitBackToTop?: boolean;
  lightHeader?: boolean;
  keepHeader?: boolean;
};

const Layout: FC<Props> = ({
  children,
  menu,
  footer,
  data,
  omitBackToTop = false,
  lightHeader = false,
  keepHeader = false,
}) => {
  const scrollDirection = useScrollDirection();
  const hideHeader = !keepHeader && scrollDirection === "down";
  return (
    <Fragment>
      <Head>
        {data ? (
          <Fragment>
            <title>{data.meta_title || 'Silicon Rhino Website'}</title>
            <MetaTags
              metaData={{
                title: data.meta_title,
                description: data.meta_description,
                social_cards: data.social_cards,
              }}
              url={data.url}
            />
          </Fragment>
        ) : (
          <title>Silicon Rhino Website</title>
        )}
      </Head>
      <Header menu={menu.data} isLight={lightHeader} hideHeader={hideHeader}/>
      <main>
        {!omitBackToTop && <BackToTop />}
        {children}
      </main>
      {footer && <Footer links={footer.data} />}
    </Fragment>
  );
};

export default Layout;
