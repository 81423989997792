import { Box } from '@mui/material';
import classnames from 'classnames';
import React, { FC } from 'react';
import { SRWIcon } from '..';
import styles from './CarouselControls.module.scss';

interface CarouselControlsProps {
  onClickLeft?: () => void;
  onClickRight?: () => void;
  size?: 'small' | 'large';
  leftGreyed?: boolean;
  rightGreyed?: boolean;
  leftArrowClasses?: string[];
  rightArrowClasses?: string[];
}

const CarouselControls: FC<CarouselControlsProps> = ({
  size = 'large',
  onClickLeft,
  onClickRight,
  leftGreyed,
  rightGreyed,
  leftArrowClasses = [],
  rightArrowClasses = [],
}) => {
  const isSmall = size === 'small';

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      className={classnames(styles.carouselControls, {
        [styles.large]: !isSmall,
        [styles.small]: isSmall,
      })}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        onClick={onClickLeft}
        className={classnames(leftArrowClasses.join(' '), {
          [styles.greyed]: !!leftGreyed,
        })}
      >
        <SRWIcon name="arrowLeft" size={`${isSmall ? '2x' : '3x'}`} />
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        onClick={onClickRight}
        className={classnames(rightArrowClasses.join(' '), {
          [styles.greyed]: !!rightGreyed,
        })}
      >
        <SRWIcon name="arrowRight" size={`${isSmall ? '2x' : '3x'}`} />
      </Box>
    </Box>
  );
};

export default CarouselControls;
