import * as React from "react";
import styles from "./Pagination.module.scss";
import PaginationButton from "./PaginationButton";
import classNames from "classnames";
import { Box, Typography } from "@mui/material";
import SRWIcon from "../SRWIcon";
import { FONT_COLOR_WHITE } from "../../theme";

interface Props {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPageSelect: (page: number) => void;
}

const MAXIMUM_PAGINATION_ITEMS = 5;

const Pagination: React.FC<Props> = (props) => {
  const { totalCount, pageSize, currentPage, onPageSelect } = props;
  const totalPages = Math.ceil(totalCount / pageSize);

  const pagesBeforeCurrent = currentPage - 1;
  const pagesAfterCurrent = totalPages - currentPage;
  const pagesEitherSide = Math.floor(MAXIMUM_PAGINATION_ITEMS / 2);
  let remaining = MAXIMUM_PAGINATION_ITEMS - 1;

  let numberToShowBefore = pagesEitherSide <= pagesBeforeCurrent ? pagesEitherSide : pagesBeforeCurrent;
  remaining -= numberToShowBefore;

  const numberToShowAfter = remaining <= pagesAfterCurrent ? remaining : pagesAfterCurrent;
  remaining -= numberToShowAfter;

  if (remaining && pagesBeforeCurrent > numberToShowBefore) {
    const remainingBefore = pagesBeforeCurrent - numberToShowBefore;
    numberToShowBefore += remainingBefore >= remaining ? remaining : remainingBefore;
  }

  const startPaginationAt = currentPage - numberToShowBefore;
  const endPaginationAt = currentPage + numberToShowAfter;
  const numberOfPaginationItems = numberToShowBefore + 1 + numberToShowAfter;

  if (totalPages === 1) {
    return null;
  }

  const leftIsDisabled = currentPage === 1;
  const rightIsDisabled = currentPage === totalPages;

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"center"}
      py={3}
      alignItems={"center"}
      className={styles.pagination}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        className={classNames(styles.actionButton, {
          [styles.disabled]: leftIsDisabled,
        })}
        onClick={!leftIsDisabled ? () => onPageSelect(currentPage - 1) : undefined}
        alignItems={"center"}>
        <SRWIcon name={"arrowLeft"} color={FONT_COLOR_WHITE} size={"lg"} />
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        {startPaginationAt > 2 && <PaginationButton label={1} onClick={() => onPageSelect(1)} />}
        {startPaginationAt > 3 && (
          <Box className={styles.dotes_box} display={"flex"} alignItems={"center"}>
            <Typography className={styles.dotes}>...</Typography>
          </Box>
        )}
        {Array.from({ length: numberOfPaginationItems }).map((_i, k) => {
          const current = startPaginationAt + k;
          return (
            <PaginationButton
              label={current}
              onClick={() => onPageSelect(current)}
              variant={currentPage === current ? "secondary" : "primary"}
              key={current}
            />
          );
        })}
        {endPaginationAt < totalPages - 1 && (
          <Box className={styles.dotes_box} display={"flex"} alignItems={"center"}>
            <Typography className={styles.dotes}>...</Typography>
          </Box>
        )}
        {endPaginationAt < totalPages && (
          <PaginationButton label={totalPages} onClick={() => onPageSelect(totalPages)} />
        )}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        className={classNames(styles.actionButton, {
          [styles.disabled]: rightIsDisabled,
        })}
        onClick={!rightIsDisabled ? () => onPageSelect(currentPage + 1) : undefined}
        alignItems={"center"}>
        <SRWIcon name={"arrowRight"} color={FONT_COLOR_WHITE} size={"lg"} />
      </Box>
    </Box>
  );
};

export default Pagination;
