import React, { FC } from 'react';
import Image from "next/image";
import { Box, BoxProps } from '@mui/material';
import styles from './Image.module.scss';
import classNames from 'classnames';

type Props = BoxProps & {
  image: any;
  type?: 'cover';
  grow?: boolean;
  className?: string;
};

const SRWImage: FC<Props> = ({
  image,
  type = 'contain',
  grow = false,
  className,
  ...props
}) => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      className={classNames(styles[type], className, {
        [styles.grow]: grow,
      })}
      flexGrow={1}
      {...props}
    >
      <Image src={image.url} alt={image.alt} fill sizes="100vw" />
    </Box>
  );
};

export default SRWImage;
