// -- Prismic Repo Name
export const repoName = 'siliconrhino-website-v3';

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = `https://${repoName}.cdn.prismic.io/api/v2`;

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = undefined;

export type DocumentLink = { type: string; uid: any, link_type?: string, url: string };
// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc: DocumentLink) => {
  if ((!doc.type || doc.link_type === "Web") && doc.url) {
    return doc.url;
  } 
  switch (doc.type) {
    case 'home':
      return '/';
    case 'about':
      return '/about';
    case 'contact':
      return '/contact';
    case 'career':
      return '/careers';
    case 'services':
      return '/services';
    case 'service':
      return `/services/${doc.uid}`;
    case 'projects':
      return '/projects';
    case 'project':
      return `/projects/${doc.uid}`;
    case 'resources':
      return '/resources';
    case 'resource':
      return `/resources/${doc.uid}`;
    case 'page':
      return `/${doc.uid}`;
    default:
      return '/';
  }
};

export type Route = { type: string; path: string };

// -- Route Resolver rules
// Manages the url links to internal Prismic documents two levels deep (optionals)
export const Router: { routes: Route[] } = {
  routes: [
    {
      type: 'home',
      path: '/',
    },
    {
      type: 'about',
      path: '/about',
    },
    {
      type: 'contact',
      path: '/contact',
    },
    {
      type: 'career',
      path: '/careers',
    },
    {
      type: 'projects',
      path: '/projects',
    },
    {
      type: 'services',
      path: '/services',
    },
    {
      type: 'resources',
      path: '/resources',
    },
    {
      type: 'page',
      path: '/:uid',
    },
    {
      type: 'project',
      path: '/projects/:uid',
    },
    {
      type: 'service',
      path: '/services/:uid',
    },
    {
      type: 'resource',
      path: '/resources/:uid',
    },
  ],
};
