import { Box, Grid, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import styles from './ResourcesGrid.module.scss';
import ProjectSummaryCard from '../ProjectSummaryCard';
import { PrismicLink } from '@prismicio/react';
import Filters, { IMenuItem } from '../Filters';
import RichText from '../RichText';

type Props = {
  resources: any;
  categories: any;
};

const ResourcesGrid: FC<Props> = ({ resources, categories }) => {
  const filter: IMenuItem[] = [{ displayText: 'ALL', key: 'all' }].concat(
    categories.map((c: string) => {
      return { displayText: c, key: c };
    })
  );

  const [selected, setSelected] = useState<number>(0);
  const [list, setList] = useState<any[]>(resources);
  const onClick = (key: string) => {
    const index = filter.findIndex((i) => i.key === key);
    if (index !== -1) {
      setSelected(index);
      setList(
        key === 'all'
          ? resources
          : resources.filter((i: any) => i.data.category.uid === key)
      );
    }
  };
  return (
    <div className={styles.resources}>
      <section>
        <Box mb={{ xs: 3, sm: 8 }}>
          <Filters selected={selected} items={filter} onClick={onClick} />
        </Box>
        {list && list.length > 0 ? (
          <Grid container spacing={2}>
            {list.map((resource: any) => (
              <Grid
                item
                sm={6}
                xs={12}
                className={styles.card}
                key={resource.uid}
              >
                <PrismicLink href={`/resources/${resource.uid}`}>
                  <ProjectSummaryCard
                    image={resource.data.image}
                    title={resource.data.title}
                    type={resource.data.category.uid}
                  />
                </PrismicLink>
                <Box mt={2}>
                  <RichText field={resource.data.text} />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box my={15} textAlign="center">
            <Typography variant="h5">
              There are currently no resources in this category
            </Typography>
          </Box>
        )}
      </section>
    </div>
  );
};

export default ResourcesGrid;
