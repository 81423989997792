import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";

export interface Selectitem {
  displayName: string;
  value: string;
}

interface Props {
  items: Selectitem[];
  onSelect: (value: string) => void;
  fullWidth?: boolean;
  selectedItem?: string;
}
const SRWSelect = (props: Props): JSX.Element => {
  const { items, onSelect, fullWidth, selectedItem } = props;
  const [selected, setSelected] = useState<string | undefined>(selectedItem);

  const handleSelect = (value: string) => {
    onSelect(value);
    setSelected(value);
  };

  return (
    <FormControl variant="standard" fullWidth={fullWidth}>
      <Select
        id="srw-select-standard"
        value={selected}
        onChange={(event: SelectChangeEvent) => handleSelect(event.target.value)}>
        {items.map((item, i) => {
          return (
            <MenuItem key={`${i}`} value={item.value}>
              {item.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SRWSelect;
