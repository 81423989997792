import React, { FC } from 'react';
import { PrismicLink } from '@prismicio/react';
import classNames from 'classnames';
import styles from './Footer.module.scss';
import { Box, Typography } from '@mui/material';
import SocialIcons from '../SocialIcons';
import SRWMenu from '../Menu';

type FooterProps = {
  links: any;
};

const Footer: FC<FooterProps> = ({ links = null }) => {
  if (!links) {
    return null;
  }

  return (
    <footer className={classNames('dark', styles.footer)}>
      <section className={styles.container}>
        <Box
          display="flex"
          p={2}
          flexDirection="column"
          justifyContent="center"
        >
          <Box flexGrow={1} py={{ xs: 8, sm: 23 }}>
            <Typography variant="h1" component="h2">
              Have an idea?
            </Typography>
            <PrismicLink
              href='/contact'
              className={styles.link}
            >
              <Typography variant="h1" component="h2" color="primary">
                Let&apos;s talk
              </Typography>
            </PrismicLink>
          </Box>
          <SRWMenu menu={links.pageLinks} isFooter />
          <Box m="auto" py={4}>
            <SocialIcons size={'small'} isDark={true} />
          </Box>
          <Box m="auto">
            <Typography variant="body3">
              &copy; {new Date().getUTCFullYear()} silicon rhino
            </Typography>
          </Box>
        </Box>
      </section>
    </footer>
  );
};

export default Footer;
