import React from 'react';
import { Box, Typography } from '@mui/material';
import SRWIcon, { IconNameType } from '../SRWIcon';

interface Props {
  title: string;
  text: string;
  iconName: IconNameType;
  bgcolor?: string;
  iconColor?: string;
}

const IconTextBox: React.FC<Props> = ({
  bgcolor,
  title,
  text,
  iconName,
  iconColor,
}) => (
  <Box bgcolor={bgcolor} display="flex" py={2}>
    <Box display="flex" alignItems="center" px={3}>
      <SRWIcon name={iconName} color={iconColor} size="lg" />
    </Box>
    <Box mr={2}>
      <Typography variant="h6">
        {title}
      </Typography>
      <Typography>{text}</Typography>
    </Box>
  </Box>
);

export default IconTextBox;
