import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const chunk = (input: any[], size: number): any[] =>
  input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);

export const useIsMobile = (breakpoint: Breakpoint = 'sm'): boolean => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(breakpoint));

  return matches;
};
