import React, { FC, Fragment, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { AppBar, Box, Button, Typography } from '@mui/material';
import logoNavy from '../../../public/linear-duotone-navy-logo.svg';
import logoWhite from '../../../public/linear-duotone-white-logo.svg';
import logoRhino from '../../../public/logo-rhino-duotone.svg';
import styles from './Header.module.scss';
import { SocialIcons, SRWIcon } from '..';
import SRWMenu from '../Menu';
import SRWDrawer from '../SRWDrawer';
import { PrismicLink } from '@prismicio/react';
import { useIsMobile } from '../../utils/helpers';

type HeaderProps = {
  menu: any;
  hideHeader: boolean;
  isLight?: boolean;
};

const Header: FC<HeaderProps> = ({ menu, isLight = false, hideHeader }) => {
  const isMobile = useIsMobile('md');
  const [showMenuDrawer, setShowMenuDrawer] = useState<boolean>(false);

  if (!menu) {
    return null;
  }

  return (
    <AppBar
      position="sticky"
      className={classNames(styles.appBar, {
        dark: !isLight,
        light: isLight,
        [styles.hideHeader]: hideHeader,
      })}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        px={{ xs: 2.5, md: 5 }}
        py={{ xs: 3, md: null }}
      >
        <Box display="flex">
          <Box>
            <PrismicLink href='/' >
              <Image
                className={styles.logo}
                src={isMobile ? logoRhino : isLight ? logoNavy : logoWhite}
                alt="Silicon Rhino"
                height={isMobile ? '50' : '77'}
                width={isMobile ? '50' : '202'}
                priority
              />
            </PrismicLink>
          </Box>
        </Box>
        {!isMobile && (
          <Fragment>
            <Box flexGrow={1}>
              <SRWMenu menu={menu.menuLinks} isLight={isLight} />
            </Box>
            <Box>
              <PrismicLink href='/contact'>
                <Button variant="outlined" color={isLight ? 'light' : 'dark'}>
                  <Typography variant={'body5'}>Get a quote</Typography>
                </Button>
              </PrismicLink>
            </Box>
          </Fragment>
        )}
        {isMobile && (
          <Box flexGrow={1} textAlign="end">
            <SRWIcon
              name={'burgerMenu'}
              size={'2x'}
              onClick={() => setShowMenuDrawer(true)}
            />
            <SRWDrawer
              open={showMenuDrawer}
              onClose={() => setShowMenuDrawer(false)}
              isLight={true}
            >
              <SRWMenu menu={menu.menuLinks} isLight={isLight} />
              <Box px={3}>
                <PrismicLink href='/contact'>
                  <Button variant="menu" color={'inherit'}>
                    <Typography
                      variant={isMobile ? 'h3' : 'button'}
                      textTransform={isMobile ? 'capitalize' : 'uppercase'}
                    >
                      Contact
                    </Typography>
                  </Button>
                </PrismicLink>
              </Box>
              <Box px={2} mt={2}>
                <SocialIcons size={'small'} />
              </Box>
            </SRWDrawer>
          </Box>
        )}
      </Box>
    </AppBar>
  );
};

export default Header;
