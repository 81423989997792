'use client'
import React, { Fragment } from 'react';
import { Box, Grid } from '@mui/material';
import styles from './ProjectsGrid.module.scss';
import ProjectSummaryCard from '../ProjectSummaryCard';
import ProjectSummaryCardHero from '../ProjectSummaryCardHero';
import { PrismicLink } from '@prismicio/react';
import RichText from '../RichText';


export type Projects = {
  isHero: false;
  projects: any[];
};
export type ProjectHero = {
  isHero: true;
  project: any;
};
export type ProjectGroup = ProjectHero | Projects;
export interface Props {
  projectGroups: ProjectGroup[];
}

const ProjectsGrid: React.FC<Props> = ({ projectGroups }) => {
  // console.log("groups", JSON.stringify(projectGroups));
  return (
    <div className={styles.projects}>
      {projectGroups.map((group, index) => (
        <Fragment key={`projectList-${index}`}>
          {!!group.isHero ? (
            <div className="wide dark">
              <section>
                <Grid item xs={12} className={styles.card}>
                  {!!group.project.data.readyToPublish ? (
                    <PrismicLink href={`/projects/${group.project.uid}`}>
                      <ProjectSummaryCardHero
                        image={group.project.data.image}
                        title={group.project.data.title}
                        text={group.project.data.text}
                        subtitle={group.project.data.subtitle}
                        type={group.project.data.type}
                      />
                    </PrismicLink>
                  ) : (
                    <ProjectSummaryCardHero
                      image={group.project.data.image}
                      title={group.project.data.title}
                      text={group.project.data.text}
                      subtitle={group.project.data.subtitle}
                      type={group.project.data.type}
                    />
                  )}
                </Grid>
              </section>
            </div>
          ) : (
            <section>
              <Grid container spacing={{ xs: 0, sm: 2, md: 4 }}>
                {group.projects?.length &&
                  group.projects.map((project: any) => (
                    <Grid
                      item
                      sm={6}
                      xs={12}
                      className={styles.card}
                      key={project.uid}
                    >
                      {!!project.data.readyToPublish ? (
                        <PrismicLink href={project.url}>
                          <ProjectSummaryCard
                            image={project.data.image}
                            title={project.data.title}
                            type={project.data.type}
                          />
                        </PrismicLink>
                      ) : (
                        <ProjectSummaryCard
                          image={project.data.image}
                          title={project.data.title}
                          type={project.data.type}
                        />
                      )}
                      <Box mt={2}>
                        <RichText field={project.data.text} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </section>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ProjectsGrid;
