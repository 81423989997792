import { Box, Button, Typography } from '@mui/material';
import { PrismicLink, PrismicText } from '@prismicio/react';
import React, { FC } from 'react';
import { useIsMobile } from '../../utils/helpers';
import { SRW_FONT_WIDER } from '../../theme/fonts';

export type menuLink = { link: any; label: any };

interface MenuProps {
  menu: menuLink[];
  isFooter?: boolean;
  isLight?: boolean;
}

const SRWMenu: FC<MenuProps> = ({
  menu,
  isFooter = false,
  isLight = false,
}) => {
  const isMobile = useIsMobile('md');
  if (!menu) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      {menu.map((link: menuLink, index: number) => (
        <Box px={{ xs: 3, md: 0 }} key={`menulink-${index}`}>
          <PrismicLink href={link.link.url}>
            <Button
              variant="menu"
              color={!(isMobile && !isFooter) && isLight ? 'light' : 'dark'}
            >
              <Typography
                variant={isMobile && !isFooter ? 'h3' : 'button'}
                textTransform={
                  isMobile && !isFooter ? 'capitalize' : 'uppercase'
                }
                letterSpacing={isMobile && !isFooter ? 0 : SRW_FONT_WIDER}
              >
                <PrismicText field={link.label} />
              </Typography>
            </Button>
          </PrismicLink>
        </Box>
      ))}
    </Box>
  );
};

export default SRWMenu;
