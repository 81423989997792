import React, { FC } from "react";
import { Chip } from "@mui/material";
import styles from "./Tags.module.scss";
interface Props {
  label: string;
  onClick?: () => void;
}

const Tags: FC<Props> = (props) => {
  const { label, onClick } = props;
  return <Chip className={styles.tags} label={label} onClick={onClick} />;
};

export default Tags;
