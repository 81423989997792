import React, { FC } from "react";
import styles from "./HorizontalLine.module.scss";
import classNames from "classnames";

type Props = {
  center?: boolean;
  accent?: boolean;
};

const HorizontalLine: FC<Props> = ({ center = false, accent = false }) => {
  return (
    <div
      className={classNames(styles.HorizontalLine, {
        [styles.center]: center,
        [styles.primary]: !accent,
        [styles.accent]: accent,
      })}
    ></div>
  );
};

export default HorizontalLine;
