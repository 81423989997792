import React, { FC } from 'react';
import { Box, Drawer } from '@mui/material';
import SRWIcon from '../SRWIcon';
import styles from './SRWDrawer.module.scss';
import classNames from 'classnames';

type DrawerProps = {
  open: boolean;
  onClose?: () => void;
  isLight?: boolean;
};

const SRWDrawer: FC<DrawerProps> = ({
  open,
  onClose,
  children,
  isLight = false,
}) => {
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      className={styles.SRWMenuDrawer}
      PaperProps={{
        className: classNames(styles.paper, {
          [styles.dark]: !isLight,
          [styles.light]: isLight,
        }),
      }}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display={'flex'}
          px={1}
          justifyContent={'flex-end'}
          flexDirection={'row'}
        >
          {onClose ? (
            <Box
              display={'flex'}
              p={2}
              onClick={onClose}
              className={styles.cross}
            >
              <SRWIcon size={'3x'} name={'times'} />
            </Box>
          ) : null}
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};

export default SRWDrawer;
