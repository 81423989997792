'use client';
import React from 'react';
import { Box } from '@mui/material';
import styles from './ProjectSummaryCard.module.scss';
import Tags from '../Tags';
import RichText from '../RichText';
import SRWImage from '../SRWImage';
import { useIsMobile } from '../../utils/helpers';

export interface ProjectSummaryCardProps {
  image: any;
  title: any;
  type?: any;
}

const ProjectSummaryCard: React.FC<ProjectSummaryCardProps> = ({
  title,
  image,
  type,
}) => {
  const isMobile = useIsMobile();
  return (
    <Box display={'flex'} className={styles.projectSummaryCard}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        minWidth="100%"
      >
        {image && image.url && (
          <SRWImage
            image={image}
            minHeight={isMobile ? '215px' : '368px'}
            type="cover"
            className={styles.image}
            grow
          />
        )}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={2}
        >
          <Box>
            <RichText field={title} />
          </Box>
          {type && (
            <Box>
              <Tags label={type} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectSummaryCard;
