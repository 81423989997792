import React, { useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { RichText } from '..';
import SRWIcon, { IconNameType } from '../SRWIcon';
import IconTextBox from '../IconTextBox';
import styles from './UserBioPanel.module.scss';
import { SRW_COLOR_PRIMARY, SRW_LIGHT_GREEN } from '../../theme';
import SocialIcons, { SocialIcon } from '../SocialIcons';

const rightPanel: Array<{ title: string; key: string; icon: IconNameType }> = [
  { title: 'Afternoon snack', key: 'snack', icon: 'utensils' },
  { title: 'Hobbies', key: 'hobbies', icon: 'telescope' },
  { title: 'Game of choice', key: 'game', icon: 'tableTennis' },
  { title: 'Superpower', key: 'superPower', icon: 'fistRaised' },
  { title: 'Favourite app', key: 'app', icon: 'mobileAlt' },
];

interface Props {
  user: {
    id: string;
    data: { [char: string]: any };
  };
  onClose: () => void;
}

const UserBioPanel: React.FC<Props> = ({ user: { id, data }, onClose }) => {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [ref, id]);

  const icons: SocialIcon[] = [];
  if (data.email) {
    icons.push({ icon: 'envelope', link: `mailto:${data.email}` });
  }
  if (data.twitter) {
    icons.push({ icon: 'twitter', link: data.twitter });
  }
  if (data.linkedIn) {
    icons.push({ icon: 'linkedIn', link: data.linkedIn });
  }

  return (
    <Box className={styles.card} my={{ md: 4 }} ref={ref}>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onClose}>
          <SRWIcon name="times" size="3x" color="#0E1A35" />
        </Button>
      </Box>
      <Box className={styles.content} px={{ xs: 0, md: 5 }}>
        <Box mt={-4.5} mb={5}>
          <Typography variant="h3">{data.firstName}</Typography>
          <Box py={2} pr={{ xs: 0, md: 3 }} mb={{ xs: 3 }}>
            <RichText field={data.text} />
          </Box>
          {icons.length > 0 && (
            <Box>
              <SocialIcons icons={icons} size="small" />
            </Box>
          )}
        </Box>
        <Box mt={{ sm: 1 }} mb={{ sm: 3 }}>
          {rightPanel.map((rp) => {
            if (data[rp.key]) {
              return (
                <Box mb={0.5} key={data[rp.key]}>
                  <IconTextBox
                    title={rp.title}
                    text={data[rp.key]}
                    iconName={rp.icon}
                    bgcolor={SRW_LIGHT_GREEN}
                    iconColor={SRW_COLOR_PRIMARY}
                  />
                </Box>
              );
            }
            return null;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default UserBioPanel;
