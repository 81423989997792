import * as React from "react";
import classnames from "classnames";
import styles from "./PaginationButton.module.scss";
import { Box, Typography } from "@mui/material";

interface Props {
  variant?: "primary" | "secondary";
  onClick?: () => void;
  label: number;
}

export default function PaginationButton(props: Props): JSX.Element {
  const { onClick, label, variant = "primary" } = props;
  const isPrimary = variant === "primary";
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={onClick}
      className={classnames(styles.paginationButton, {
        [styles.primary]: isPrimary,
        [styles.secondary]: !isPrimary,
      })}>
      <Typography className={styles.label}>{label}</Typography>
    </Box>
  );
}
