import { Typography } from '@mui/material';
import {
  PrismicLink,
  PrismicRichText,
  PrismicRichTextProps,
} from '@prismicio/react';
import React, { Fragment } from 'react';
import * as prismicR from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import { linkResolver } from '../../../prismic-configuration';

const serializer: prismicR.JSXMapSerializer = {
  heading1: ({ children }) => <Typography variant="h1">{children}</Typography>,
  heading2: ({ children }) => <Typography variant="h2">{children}</Typography>,
  heading3: ({ children }) => <Typography variant="h3">{children}</Typography>,
  heading4: ({ children }) => <Typography variant="h4">{children}</Typography>,
  heading5: ({ children }) => <Typography variant="h5">{children}</Typography>,
  heading6: ({ children }) => <Typography variant="h6">{children}</Typography>,
  paragraph: ({ children }) => (
    <Typography variant="body1">{children}</Typography>
  ),
  preformatted: ({ node }) => <pre>{JSON.stringify(node.text)}</pre>,
  strong: ({ children }) => <strong>{children}</strong>,
  em: ({ children }) => <em>{children}</em>,
  listItem: ({ children }) => <li>{children}</li>,
  oListItem: ({ children }) => <li>{children}</li>,
  list: ({ children }) => <ul>{children}</ul>,
  oList: ({ children }) => <ol>{children}</ol>,
  image: ({ node }) => {
    const linkUrl = node.linkTo ? linkResolver(node.linkTo as any) : null;
    // eslint-disable-next-line jsx-a11y/alt-text
    const img = <PrismicNextImage field={node as any} />;

    return linkUrl ? <a href={linkUrl}>{img}</a> : img;
  },
  embed: ({ node }) => (
    <div
      data-oembed="${node.oembed.embed_url}"
      data-oembed-type="${node.oembed.type}"
      data-oembed-provider="${node.oembed.provider_name}"
    >
      {node.oembed.html}
    </div>
  ),
  hyperlink: ({ node, children }) => {
    const url = linkResolver(node.data as any);
    return (
      <PrismicLink href={url} className="hyperLink">
        {children}
      </PrismicLink>
    );
  },
  label: ({ node, children }) => {
    return <span className={node.data.label}>{children}</span>;
  },
  span: ({ text }) => <Fragment>{text ? text : ''}</Fragment>,
};

const RichText: (prop: PrismicRichTextProps) => JSX.Element = (prop) => (
  <PrismicRichText {...prop} components={serializer} />
);

export default RichText;
