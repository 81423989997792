import { Box, Typography } from '@mui/material';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useIsMobile } from '../../utils/helpers';
import SRWSelect, { Selectitem } from '../SRWSelect';
import styles from './Filters.module.scss';

export interface IMenuItem {
  displayText: string;
  key: string;
}

interface FiltersProps {
  selected: number;
  items: IMenuItem[];
  onClick: (key: string) => void;
}
const Filters: FC<FiltersProps> = (props) => {
  const { selected, items, onClick } = props;
  const isMobile = useIsMobile('md');

  const mobileData = isMobile
    ? items.map((item) => {
        return {
          displayName: item.displayText.toUpperCase(),
          value: item.key,
        } as Selectitem;
      })
    : [];
  return isMobile ? (
    <Box className={styles.filters_mobile} display={'flex'} px={2}>
      <SRWSelect
        fullWidth={true}
        items={mobileData}
        selectedItem={items[selected].key}
        onSelect={(v) => onClick(v)}
      />
    </Box>
  ) : (
    <Box display={'flex'} flexDirection={'row'} className={styles.filters}>
      {items.map((item, i) => {
        return (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            className={classnames(styles.filters_item, {
              [styles.selected]: selected === i,
            })}
            key={`${item.key}-${i}`}
            onClick={() => onClick(item.key)}
          >
            <Typography variant='body4'> {item.displayText} </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Filters;
