'use client'
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from './ProjectSummaryCardHero.module.scss';
import Tags from '../Tags';
import { ProjectSummaryCardProps } from '../ProjectSummaryCard';
import SRWImage from '../SRWImage';
import RichText from '../RichText';
import HorizontalLine from '../HorizontalLine';
import { useIsMobile } from '../../utils/helpers';

interface Props extends ProjectSummaryCardProps {
  text: any;
  subtitle: any;
}

const ProjectSummaryCardHero: React.FC<Props> = ({
  title,
  text,
  image,
  type,
  subtitle,
}) => {
  const isMobile = useIsMobile();

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      className={styles.projectSummaryCardHero}
    >
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={6}>
          <SRWImage
            image={image}
            minHeight={isMobile ? '375px' : '642px'}
            type="cover"
            flexGrow={1}
            className={styles.image}
            grow
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            pt={{ xs: 5, sm: 0 }}
            pl={{ sm: 6, md: 12 }}
            pr={{ sm: 4 }}
            flexGrow={1}
          >
            {type && (
              <Box pb={2}>
                <Tags label={type} />
              </Box>
            )}
            <Box>
              <RichText field={title} />
            </Box>
            {subtitle && (
              <Box>
                <RichText field={subtitle} />
              </Box>
            )}
            <Box>
              <HorizontalLine />
            </Box>
            <Box>
              {typeof text === 'string' ? (
                <Typography variant="body1">{text}</Typography>
              ) : (
                <RichText field={text} />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectSummaryCardHero;
