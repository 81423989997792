import { Box } from '@mui/material';
import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { FONT_COLOR_WHITE } from '../../theme';
import { useIsMobile } from '../../utils/helpers';
import SRWIcon from '../SRWIcon';
import styles from './BackToTop.module.scss';

const BackToTop: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);
  const [bottom, setBottom] = useState<number>(0);
  const [right, setRight] = useState<number>(0);
  const isMobile = useIsMobile();
  const isLarge = !isMobile;
  const isPrimary = true;

  const DISPLAY_AT = 400;

  const updateDimensions = useCallback(() => {
    if (typeof window !== 'undefined') {
      setHeight(
        window.document.body.offsetHeight - window.innerHeight - DISPLAY_AT
      );
    }
  }, []);

  useEffect(() => {
    updateDimensions();
    const resizeHandler = () => {
      updateDimensions();
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [updateDimensions]);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > DISPLAY_AT && height > 0) {
        // 100% for each side of the square, so 400% in total
        const squarePercentage = Math.round(
          ((currPos.y - DISPLAY_AT) / height) * 400
        );
        let newTop = 0;
        let newLeft = 0;
        let newBottom = 0;
        let newRight = 0;
        if (squarePercentage < 100) {
          newTop = squarePercentage;
        } else {
          newTop = 100;
          if (squarePercentage < 200) {
            newLeft = squarePercentage - 100;
          } else {
            newLeft = 100;
            if (squarePercentage < 300) {
              newBottom = squarePercentage - 200;
            } else {
              newBottom = 100;
              newRight = squarePercentage - 300;
              if (newRight > 100) {
                newRight = 100;
              }
            }
          }
        }
        if (top !== newTop) {
          setTop(newTop);
        }
        if (left !== newLeft) {
          setLeft(newLeft);
        }
        if (bottom !== newBottom) {
          setBottom(newBottom);
        }
        if (right !== newRight) {
          setRight(newRight);
        }
        setShow(true);
      } else if (show) {
        setShow(false);
      }
    },
    [left, right, top, bottom, height, show],
    undefined,
    true,
    50
  );

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setShow(false);
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={scrollToTop}
      className={classnames(styles.backToTop, {
        [styles.large]: isLarge,
        [styles.small]: !isLarge,
      })}
      sx={{ opacity: show ? 1 : 0 }}
    >
      <Box className={styles.top}>
        <Box width={`${top}%`} />
      </Box>
      <Box className={styles.left}>
        <Box height={`${left}%`} />
      </Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className={classnames(styles.inner, {
          [styles.primary]: isPrimary,
          [styles.secondary]: !isPrimary,
          [styles.inner_large]: isLarge,
          [styles.inner_small]: !isLarge,
        })}
      >
        <SRWIcon
          name="arrowUp"
          size={`${isLarge ? '3x' : '2x'}`}
          color={FONT_COLOR_WHITE}
        />
      </Box>
      <Box className={styles.bottom}>
        <Box width={`${bottom}%`} />
      </Box>
      <Box className={styles.right}>
        <Box height={`${right}%`} />
      </Box>
    </Box>
  );
};

export default BackToTop;
