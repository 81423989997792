import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './SummaryCard.module.scss';

interface Props {
  imageUrl: string;
  title: string;
  subtitle: string;
}

const SummaryCard: React.FC<Props> = ({ title, subtitle, imageUrl }) => (
  <Box className={styles.summaryCard}>
    <Box
      className={styles.image}
      style={{ backgroundImage: `url(${imageUrl})` }}
    ></Box>
    <Box className={styles.textBox}>
      <Typography className={styles.title} variant="h5">
        <b>{title}</b>
      </Typography>
      <Typography variant="h6" className={styles.subtitle}>
        {subtitle}
      </Typography>
    </Box>
  </Box>
);

export default SummaryCard;
