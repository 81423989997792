import { useState, useEffect } from "react";

function useScrollDirection(delta: number = 10) {
  const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > delta || scrollY - lastScrollY < -delta)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [delta, scrollDirection]);

  return scrollDirection;
};

export default useScrollDirection;
