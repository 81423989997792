import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { RichText } from '../../components';
import { PrismicLink } from '@prismicio/react';
import styles from './Position.module.scss';

type Props = {
  position: any;
};

const Position: FC<Props> = ({ position }) => {
  const { title, description, link, label } = position;
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'center', sm: 'unset' }}
      justifyContent="space-between"
      p={4}
      mb={3}
      className={styles.card}
    >
      <Box textAlign={{ xs: 'center', sm: 'unset' }} mb={{ xs: 2, sm: 0 }}>
        <RichText field={title} />
        <RichText field={description} />
      </Box>
      <Box>
        <PrismicLink href={link.url ? link.url : '/apply'}>
          <Button variant="outlined" color="primary">
            {label}
          </Button>
        </PrismicLink>
      </Box>
    </Box>
  );
};

export default Position;
