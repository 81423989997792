import { Box } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { FONT_COLOR_WHITE, SRW_COLOR_PRIMARY } from '../../theme';
import { SRWIcon, SocialIconNames } from '..';
import styles from './SocialIcons.module.scss';

export type SocialIcon = {
  icon: SocialIconNames;
  link: string;
};

interface SocialIconsProps {
  size?: 'small';
  isDark?: boolean;
  icons?: SocialIcon[];
}

const defaultIcons: SocialIcon[] = [
  { icon: 'envelope', link: 'mailto:hi@siliconrhino.io' },
  { icon: 'twitter', link: 'https://twitter.com/siliconrhino' },
  {
    icon: 'linkedIn',
    link: 'https://www.linkedin.com/company/siliconrhino',
  },
  { icon: 'gitHub', link: 'https://github.com/siliconrhino' },
];

const SocialIcons: FC<SocialIconsProps> = ({
  size = 'large',
  isDark = false,
  icons = defaultIcons,
}) => {
  const isSmall = size === 'small';

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      className={styles.socialIcons}
    >
      {icons.map((item: SocialIcon, i) => {
        return (
          <a href={item.link} key={`box-${i}`} target="_blank" rel="noreferrer">
            <Box
              display={'flex'}
              className={classNames(
                styles.icon,
                styles.clickable,
                styles[size],
                {
                  [styles.dark]: isDark,
                  [styles.light]: !isDark,
                }
              )}
              m={0.5}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <SRWIcon
                color={isDark ? FONT_COLOR_WHITE : SRW_COLOR_PRIMARY}
                name={item.icon}
                size={isSmall ? 'sm' : 'lg'}
              />
            </Box>
          </a>
        );
      })}
    </Box>
  );
};

export default SocialIcons;
